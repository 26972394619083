._Details_1tbc6_1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
._Details_1tbc6_1 ._navigation_1tbc6_6 {
  flex-shrink: 0;
  display: flex;
  width: 100%;
  padding: 3px;
  background-color: var(--gray-3);
  border-radius: var(--border-radius-base);
}
._Details_1tbc6_1 ._navigation_1tbc6_6 .ant-radio-button-wrapper {
  flex: 1;
  border: none;
  text-align: center;
  border-radius: var(--border-radius-base);
  background-color: var(--gray-3);
}
._Details_1tbc6_1 ._navigation_1tbc6_6 .ant-radio-button-wrapper::before {
  display: none;
}
._Details_1tbc6_1 ._navigation_1tbc6_6 .ant-radio-button-wrapper-checked {
  background-color: #09375a !important;
}
._Details_1tbc6_1 ._main_1tbc6_27 {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._Details_1tbc6_1 ._main_1tbc6_27 ._content_1tbc6_33 {
  padding-right: 12px;
  overflow-y: auto;
  flex: 1;
}
._Details_1tbc6_1 ._main_1tbc6_27 ._actions_1tbc6_38 {
  flex-shrink: 0;
}